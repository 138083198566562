import React from "react";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Image from "./Image";
import apiFetch from "./utils/apiFetch";
import { secondsToTime } from "./utils/time";

import styles from "./Standings.module.scss";

let levelVirusCounts = { 1: 136, 2: 168, 3: 260, 4: 320 };

function getPointsDescription(player) {
  return (
    <>
      <Typography variant="h6">
        {player.gamer_tag} total points: {player.points}
      </Typography>
      {player.firstPlace ? (
        <Typography variant="body2">
          ({player.firstPlace}) 1st place (4 points) = {player.firstPlace * 4}
        </Typography>
      ) : (
        ""
      )}
      {player.secondPlace ? (
        <Typography variant="body2">
          ({player.secondPlace}) 2nd place (2 points) = {player.secondPlace * 2}
        </Typography>
      ) : (
        ""
      )}
      {player.semis ? (
        <Typography variant="body2">
          ({player.semis}) Semifinals (1 point) = {player.semis}
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}

class Standings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerMap: {},
      currentYear: 0,
      possibleYears: new Set(),
      isLoaded: false,
    };
  }

  loadStandings(currentYear) {
    Promise.all([
      apiFetch("qualifying-attempts"),
      apiFetch("events"),
      apiFetch("rounds"),
      apiFetch("matches"),
      apiFetch("games"),
      apiFetch("players"),
    ]).then(
      ([
        qualsResponse,
        eventsResponse,
        roundsResponse,
        matchesResponse,
        gamesResponse,
        playersResponse,
      ]) => {
        const possibleYears = new Set(
          eventsResponse.map((event) =>
            event.start_date.replace(/([0-9]{4}).*/, "$1")
          )
        );
        if (currentYear === undefined) {
          currentYear = Math.max(...possibleYears);
        }
        const eventMap = new Map(
          eventsResponse
            .filter((event) => event.name.includes(currentYear))
            .map((event) => [event.id, event.name])
        );
        const qualMap = new Map(
          qualsResponse
            .filter((qual) => eventMap.has(qual.event_id))
            .map((qual) => [qual.id, qual])
        );
        const playerMap = new Map(
          playersResponse
            .filter((player) =>
              Array.from(qualMap.values())
                .map((qual) => qual.player_id)
                .includes(player.id)
            )
            .map((player) => [player.id, player])
        );
        for (let player of playerMap.values()) {
          player.events = Array.from(qualMap.values()).filter(
            (qual) => qual.attempt === 1 && qual.player_id === player.id
          ).length;
        }
        const roundMap = new Map(
          roundsResponse
            .filter((round) => eventMap.has(round.event_id))
            .map((round) => [round.id, round])
        );
        const matchMap = new Map(
          matchesResponse
            .filter((match) => roundMap.has(match.round_id))
            .map((match) => [match.id, match])
        );
        const gameList = Array.from(
          gamesResponse.filter((game) => matchMap.has(game.match_id))
        );
        for (let match of matchesResponse.filter((match) =>
          roundMap.has(match.round_id)
        )) {
          const roundNo = roundMap.get(match.round_id).order;
          if (match.player_1_id === null || match.player_2_id === null) {
            continue;
          }
          let p1 = playerMap.get(match.player_1_id) || {};
          let p2 = playerMap.get(match.player_2_id) || {};
          if (!("match_wins" in p1)) {
            p1.match_wins = p1.match_losses = 0;
            p1.total_seconds = p1.total_viruses = 0;
            p1.firstPlace = p1.secondPlace = p1.semis = 0;
            p1.roundGames = { 1: 0, 2: 0, 3: 0, 4: 0 };
            p1.roundTimes = { 1: 0, 2: 0, 3: 0, 4: 0 };
          }
          if (!("match_wins" in p2)) {
            p2.match_wins = p2.match_losses = 0;
            p2.total_seconds = p2.total_viruses = 0;
            p2.firstPlace = p2.secondPlace = p2.semis = 0;
            p2.roundGames = { 1: 0, 2: 0, 3: 0, 4: 0 };
            p2.roundTimes = { 1: 0, 2: 0, 3: 0, 4: 0 };
          }
          let p1Wins = 0;
          let p2Wins = 0;
          for (let game of gameList.filter(
            (game) => game.match_id === match.id
          )) {
            if (game.player_2_time_seconds === null) {
              p1Wins++;
            } else if (game.player_1_time_seconds === null) {
              p2Wins++;
            } else if (
              game.player_1_time_seconds < game.player_2_time_seconds
            ) {
              p1Wins++;
            } else {
              p2Wins++;
            }

            // We don't have times recorded for the early days. 20:00 for the winner and 30:00 for the loser was entered in the spreadsheet by convention. Do not use these for clear rate calculations.
            if (
              game.player_1_time_seconds === 1800 ||
              game.player_2_time_seconds === 1800 ||
              game.player_1_time_seconds === 1200 ||
              game.player_2_time_seconds === 1200
            ) {
              continue;
            }

            p1.roundGames[roundNo]++;
            p2.roundGames[roundNo]++;
            if (game.player_1_time_seconds > 0) {
              p1.total_seconds += game.player_1_time_seconds;
              p1.roundTimes[roundNo] += game.player_1_time_seconds;
              p1.total_viruses += levelVirusCounts[roundNo];
            } else {
              p1.total_seconds += game.player_2_time_seconds;
              p1.roundTimes[roundNo] +=
                (game.player_2_time_seconds / game.player_1_score) *
                levelVirusCounts[roundNo];
              p1.total_viruses += game.player_1_score;
            }
            if (game.player_2_time_seconds > 0) {
              p2.total_seconds += game.player_2_time_seconds;
              p2.roundTimes[roundNo] += game.player_2_time_seconds;
              p2.total_viruses += levelVirusCounts[roundNo];
            } else {
              p2.total_seconds += game.player_1_time_seconds;
              p2.roundTimes[roundNo] +=
                (game.player_1_time_seconds / game.player_2_score) *
                levelVirusCounts[roundNo];
              p2.total_viruses += game.player_2_score;
            }
          }

          if (roundNo === 4) {
            if (p1Wins > p2Wins) {
              p1.firstPlace++;
              p2.secondPlace++;
            } else if (p1Wins < p2Wins) {
              p2.firstPlace++;
              p1.secondPlace++;
            }
          } else if (roundNo === 3) {
            if (p1Wins > p2Wins) {
              p2.semis++;
            } else if (p1Wins < p2Wins) {
              p1.semis++;
            }
          }
          if (p1Wins > p2Wins) {
            p1.match_wins++;
            p2.match_losses++;
          } else if (p1Wins < p2Wins) {
            p2.match_wins++;
            p1.match_losses++;
          }
        }
        for (let player of playerMap.values()) {
          let points = 0;
          if (player.firstPlace) {
            points += player.firstPlace * 4;
          }
          if (player.secondPlace) {
            points += player.secondPlace * 2;
          }
          if (player.semis && currentYear > 2020) {
            points += player.semis;
          }
          player.clear_rate = player.total_seconds / player.total_viruses || 0;
          player.score = player.points = points;
          player.score -= player.clear_rate / 100;
          if (player.events < 2 || (currentYear > 2020 && player.events < 3)) {
            // prioritize players by number of events (3 required for 2021), followed by points, then clear rate
            player.score -= 1000 * (3 - player.events);
          }
          if (!player.total_seconds) {
            player.score -= 1;
          }
        }
        this.setState({
          playerMap,
          currentYear,
          possibleYears,
          isLoaded: true,
        });
      }
    );
  }

  componentDidMount() {
    this.loadStandings(this.props.match.params.year);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.year !== prevProps.match.params.year) {
      this.loadStandings(this.props.match.params.year);
    }
  }

  render() {
    const { playerMap, currentYear, possibleYears, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let rank = 1;
      return (
        <div className={styles.Standings}>
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(`/standings/${event.target.value}`);
            }}
          >
            {Array.from(possibleYears)
              .sort(function (a, b) {
                return b - a;
              })
              .map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>;
              })}
          </Select>
          <Typography variant="h3">{currentYear} Standings</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan="5"></TableCell>
                  <TableCell
                    align="center"
                    colSpan="2"
                    className={styles.Matches}
                  >
                    Matches
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan="4"
                    className={styles.AverageTime}
                  >
                    Average Time
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell align="right">Events</TableCell>
                  <TableCell align="right">Points</TableCell>
                  <TableCell align="right">Clear Rate</TableCell>
                  <TableCell align="right" className={styles.Matches}>
                    W-L
                  </TableCell>
                  <TableCell align="right" className={styles.Matches}>
                    Win%
                  </TableCell>
                  <TableCell align="right" className={styles.AverageTime}>
                    Rd. 1
                  </TableCell>
                  <TableCell align="right" className={styles.AverageTime}>
                    Rd. 2
                  </TableCell>
                  <TableCell align="right" className={styles.AverageTime}>
                    Semis
                  </TableCell>
                  <TableCell align="right" className={styles.AverageTime}>
                    Finals
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(playerMap.values())
                  .sort(function (a, b) {
                    return b.score - a.score;
                  })
                  .map((player) => {
                    const playerImage = player.twitch_user ? (
                      <Image
                        src={player.twitch_user.profile_image_url}
                        className={styles.PlayerImage}
                      />
                    ) : (
                      ""
                    );
                    return (
                      <TableRow
                        key={player.id}
                        className={
                          rank <= 16 &&
                          (player.events >= 3 ||
                            (currentYear <= 2020 && player.events >= 2))
                            ? styles.ChampionshipEligible
                            : ""
                        }
                      >
                        <TableCell align="right">{rank++}</TableCell>
                        <TableCell>
                          <div className={styles.PlayerInfo}>
                            {playerImage}
                            <Link to={"/players/" + player.id}>
                              {player.gamer_tag}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell align="right">{player.events}</TableCell>
                        <TableCell align="right">
                          <Tooltip
                            key={"tt" + player.id}
                            title={getPointsDescription(player)}
                          >
                            <div>{player.points}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          {player.clear_rate.toFixed(3)}
                        </TableCell>
                        <TableCell align="right" className={styles.Matches}>
                          {player.match_wins || 0}-{player.match_losses || 0}
                        </TableCell>
                        <TableCell align="right" className={styles.Matches}>
                          {player.match_wins || player.match_losses
                            ? (
                                (100.0 * (player.match_wins || 0)) /
                                ((player.match_wins || 0) +
                                  (player.match_losses || 0))
                              ).toFixed(2)
                            : "0.00"}
                          %
                        </TableCell>
                        <TableCell align="right" className={styles.AverageTime}>
                          {player.roundTimes && player.roundGames[1]
                            ? secondsToTime(
                                player.roundTimes[1] / player.roundGames[1]
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="right" className={styles.AverageTime}>
                          {player.roundTimes && player.roundGames[2]
                            ? secondsToTime(
                                player.roundTimes[2] / player.roundGames[2]
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="right" className={styles.AverageTime}>
                          {player.roundTimes && player.roundGames[3]
                            ? secondsToTime(
                                player.roundTimes[3] / player.roundGames[3]
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="right" className={styles.AverageTime}>
                          {player.roundTimes && player.roundGames[4]
                            ? secondsToTime(
                                player.roundTimes[4] / player.roundGames[4]
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default Standings;
