import React from "react";

import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import apiFetch from "./utils/apiFetch";

import styles from "./Events.module.scss";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    apiFetch("events").then((response) => {
      this.setState({
        events: response.sort((a, b) =>
          b.start_date.localeCompare(a.start_date)
        ),
        isLoaded: true,
      });
    });
  }

  render() {
    const { events, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          <Typography variant="h3">Events</Typography>
          <TableContainer component={Paper} className={styles.Table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Start date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>
                      <Link to={`/events/${event.id}`}>{event.name}</Link>
                    </TableCell>
                    <TableCell>
                      {DateTime.fromISO(event.start_date)
                        .setZone("UTC")
                        .toFormat("LLL d, yyyy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  }
}

export default Events;
