import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const ComboBox = ({
  options,
  optionLabelField,
  className,
  label,
  autoFocus,
  ...props
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option[optionLabelField]}
      className={className}
      renderInput={(params) => (
        <TextField {...params} label={label} autoFocus={autoFocus} />
      )}
      autoHighlight={true}
      autoSelect={true}
      clearOnEscape={true}
      {...props}
    />
  );
};
