import React from "react";
import Button from "@material-ui/core/Button";

import { ReactComponent as TwitchIcon } from "./twitch.svg";
import { CLIENT_ID, REDIRECT_URI } from "./config/oauth.twitch.config.js";
import Image from "./Image";

import styles from "./LoginButton.module.scss";

function LoginButton(props) {
  const { user } = props;
  const linkHref = user
    ? "/logout"
    : `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
  const image = user ? (
    <Image
      src={user.twitch_user.profile_image_url}
      className={styles.LoginButtonImage}
    />
  ) : (
    <TwitchIcon className={styles.LoginButtonImage} />
  );
  const text = user ? (
    <>
      <span className={styles.DisplayName}>
        {user.twitch_user.display_name}
        <span className={styles.LoginButtonDivider}>&nbsp;|&nbsp;</span>
      </span>
      Log Out
    </>
  ) : (
    "Login with Twitch"
  );
  const loginButtonClasses = user ? ` ${styles.LoginButtonUser}` : "";
  return (
    <a href={linkHref} className={styles.LoginLink}>
      <Button className={styles.LoginButton + loginButtonClasses}>
        {image}
        {text}
      </Button>
    </a>
  );
}

export default LoginButton;
