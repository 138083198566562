import React from "react";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";

import { Bracket } from "./Bracket";
import BracketDialog from "./BracketDialog";
import IfAdmin from "./IfAdmin";
import Qualifiers from "./Qualifiers";
import { scoreToVirusScore } from "./utils/virusScore";
import apiFetch from "./utils/apiFetch";

import styles from "./Event.module.scss";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      prevEvent: null,
      nextEvent: null,
      seeds: [],
      games: [],
      rounds: [],
      qualifiers: [],
      qualifyingAttempts: [],
      prevQualifyingAttempts: {},
      playerMap: {},
      isLoaded: false,
      isBracketDialogOpen: false,
    };
  }

  componentDidMount() {
    this.updateEvent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.updateEvent();
    }
  }

  updateEvent() {
    return Promise.all([
      apiFetch("events"),
      apiFetch(`events/${this.props.match.params.id}`),
      apiFetch(`events/${this.props.match.params.id}/seeds`),
      apiFetch(`events/${this.props.match.params.id}/rounds`),
      apiFetch(`events/${this.props.match.params.id}/qualifying-attempts`),
      apiFetch("players"),
    ]).then(
      ([
        eventsResponse,
        eventResponse,
        seedsResponse,
        roundsResponse,
        qualifyingAttemptsResponse,
        playersResponse,
      ]) => {
        Promise.all(
          roundsResponse.map((round) => apiFetch(`rounds/${round.id}/matches`))
        ).then((matchesResponse) => {
          Promise.all(
            matchesResponse
              .flat()
              .map((match) => apiFetch(`matches/${match.id}/games`))
          ).then((gamesResponse) => {
            const playerMap = new Map(
              playersResponse.map((player) => [player.id, player])
            );
            const qualifyingAttemptsMap = {};
            for (const qualifyingAttempt of qualifyingAttemptsResponse.sort(
              (a, b) => a.attempt - b.attempt
            )) {
              qualifyingAttempt.scoreNotation = scoreToVirusScore(
                qualifyingAttempt.score
              );
              if (qualifyingAttemptsMap[qualifyingAttempt.player_id]) {
                const lastScore =
                  qualifyingAttemptsMap[qualifyingAttempt.player_id].score;
                const lastScoreNotation =
                  qualifyingAttemptsMap[qualifyingAttempt.player_id]
                    .scoreNotation;
                const lastQualifyingAttempts =
                  qualifyingAttemptsMap[qualifyingAttempt.player_id]
                    .qualifyingAttempts;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ] = qualifyingAttempt;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ].lastScore = lastScore;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ].lastScoreNotation = lastScoreNotation;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ].qualifyingAttempts = lastQualifyingAttempts;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ].qualifyingAttempts.push(qualifyingAttempt);
              } else {
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ] = qualifyingAttempt;
                qualifyingAttemptsMap[
                  qualifyingAttempt.player_id
                ].qualifyingAttempts = [qualifyingAttempt];
              }
            }
            const gamesMap = {};
            for (const game of gamesResponse.flat()) {
              if (!gamesMap[game.match_id]) {
                gamesMap[game.match_id] = [];
              }
              gamesMap[game.match_id].push(game);
            }
            const matchMap = {};
            for (const match of matchesResponse.flat()) {
              match.games = (gamesMap[match.id] || []).sort(
                (a, b) => a.game_number - b.game_number
              );
              if (!matchMap[match.round_id]) {
                matchMap[match.round_id] = [];
              }
              matchMap[match.round_id].push(match);
            }
            for (const roundId of Object.keys(matchMap)) {
              matchMap[roundId].sort((a, b) => a.match_number - b.match_number);
            }
            const populatedRounds = roundsResponse
              .flat()
              .map((round) => {
                round.matches = matchMap[round.id] || [];
                return round;
              })
              .sort((a, b) => a.order - b.order);
            for (const round of populatedRounds) {
              for (const match of round.matches) {
                for (let game of match.games) {
                  if (game.player_1_score !== null) {
                    game.player_1_score_notation = scoreToVirusScore(
                      game.player_1_score,
                      false /* shouldPad */,
                      round.level_start
                    );
                  }
                  if (game.player_2_score !== null) {
                    game.player_2_score_notation = scoreToVirusScore(
                      game.player_2_score,
                      false /* shouldPad */,
                      round.level_start
                    );
                  }
                }
              }
            }
            eventsResponse.sort((a, b) =>
              a.start_date < b.start_date ? -1 : 1
            );
            const eventIndex = eventsResponse.findIndex(
              (e) => e.id === eventResponse.id
            );
            const prevEvent =
              eventIndex === 0 ? null : eventsResponse[eventIndex - 1];
            (prevEvent === null
              ? Promise.resolve([])
              : apiFetch(`events/${prevEvent.id}/qualifying-attempts`)
            ).then((prevQuals) => {
              this.setState({
                event: eventResponse,
                prevEvent: prevEvent,
                nextEvent:
                  eventIndex === eventsResponse.length - 1
                    ? null
                    : eventsResponse[eventIndex + 1],
                qualifiers: Object.values(qualifyingAttemptsMap).sort(
                  (a, b) => {
                    const scoreDiff = b.score - a.score;
                    if (scoreDiff === 0) {
                      return (
                        a.time_to_last_level_seconds -
                        b.time_to_last_level_seconds
                      );
                    }
                    return scoreDiff;
                  }
                ),
                qualifyingAttempts: qualifyingAttemptsResponse,
                prevQualifyingAttempts: new Map(
                  prevQuals
                    .sort((a, b) => a.attempt - b.attempt)
                    .map((attempt) => [attempt.player_id, attempt])
                ),
                rounds: populatedRounds,
                seeds: seedsResponse.sort((a, b) => a.seed - b.seed),
                playerMap,
                isLoaded: true,
              });
            });
          });
        });
      }
    );
  }

  setBracketDialogOpen(isOpen) {
    this.setState({ isBracketDialogOpen: isOpen });
  }

  render() {
    const {
      event,
      prevEvent,
      nextEvent,
      rounds,
      seeds,
      qualifiers,
      qualifyingAttempts,
      prevQualifyingAttempts,
      playerMap,
      isLoaded,
      isBracketDialogOpen,
    } = this.state;
    const { user, showSnackbar } = this.props;
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
    let bracket;
    let bracketButtonText;
    let bracketButtonIcon;
    if (seeds.length > 0) {
      bracket = (
        <Bracket
          seeds={seeds}
          rounds={rounds}
          playerMap={playerMap}
          user={user}
          updateEvent={() => this.updateEvent()}
          showSnackbar={showSnackbar}
        />
      );
      bracketButtonText = "Edit bracket";
      bracketButtonIcon = <EditIcon />;
    } else {
      bracket = (
        <Bracket
          seeds={qualifiers}
          playerMap={playerMap}
          title="Provisional Bracket"
          user={user}
          updateEvent={() => this.updateEvent()}
          showSnackbar={showSnackbar}
        />
      );
      bracketButtonText = "Create bracket";
      bracketButtonIcon = <AddIcon />;
    }
    const bracketButton = (
      <IfAdmin user={user}>
        <Button
          className={styles.BracketButton}
          variant="outlined"
          color="primary"
          startIcon={bracketButtonIcon}
          onClick={() => this.setBracketDialogOpen(true)}
        >
          <span className={styles.BracketButtonText}>{bracketButtonText}</span>
        </Button>
      </IfAdmin>
    );
    const qualifiersEl = (
      <Qualifiers
        qualifiers={qualifiers}
        qualifyingAttempts={qualifyingAttempts}
        prevQualifyingAttempts={prevQualifyingAttempts}
        playerMap={playerMap}
        showSnackbar={showSnackbar}
        updateEvent={() => this.updateEvent()}
        user={user}
        event={event}
      />
    );
    let prevEventLink;
    if (prevEvent === null) {
      prevEventLink = <></>;
    } else {
      prevEventLink = (
        <>
          <Tooltip title={prevEvent.name}>
            <Link to={"/events/" + prevEvent.id} className={styles.Link}>
              <ArrowLeft className={styles.NavigationIcons} />
            </Link>
          </Tooltip>
        </>
      );
    }
    let nextEventLink;
    if (nextEvent === null) {
      nextEventLink = <></>;
    } else {
      nextEventLink = (
        <Tooltip title={nextEvent.name}>
          <Link to={"/events/" + nextEvent.id} className={styles.Link}>
            <ArrowRight className={styles.NavigationIcons} />
          </Link>
        </Tooltip>
      );
    }
    const bracketDialog = isBracketDialogOpen ? (
      <BracketDialog
        open={isBracketDialogOpen}
        setOpen={(isOpen) => this.setBracketDialogOpen(isOpen)}
        qualifiers={qualifiers}
        seeds={seeds}
        rounds={rounds}
        playerMap={playerMap}
        eventId={this.props.match.params.id}
        updateEvent={() => this.updateEvent()}
        showSnackbar={showSnackbar}
      />
    ) : (
      ""
    );
    return (
      <div className={styles.EventContainer}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {prevEventLink}
            <Typography variant="h3" className={styles.TitleText}>
              {event.name}
            </Typography>
            {nextEventLink}
          </div>
          {bracketButton}
        </div>
        {bracketDialog}
        {bracket}
        {qualifiersEl}
      </div>
    );
  }
}

export default Event;
