class ApiError extends Error {
  constructor(errors = [], ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.name = "ApiError";
    this.errors = errors;
  }
}

export default ApiError;
