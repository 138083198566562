import React, { useState } from "react";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import apiFetch from "./utils/apiFetch";

import styles from "./AddPlayerDialog.module.scss";

const AddPlayerDialog = ({ open, update, closeDialog, showSnackbar }) => {
  // state

  const [validationErrorText, setValidationErrorText] = useState(""); // string
  const [inProgress, setInProgress] = useState(false); // bool
  const [gamerTag, setGamerTag] = useState("");
  const [twitchUsername, setTwitchUsername] = useState("");

  // calculated state

  const validationError = validationErrorText ? (
    <Alert severity="error">{validationErrorText}</Alert>
  ) : (
    ""
  );

  // handlers

  const handleGamerTagChange = (event) => {
    setGamerTag(event.target.value);
  };

  const handleTwitchUsernameChange = (event) => {
    setTwitchUsername(event.target.value);
  };

  const dismiss = () => {
    closeDialog();
  };

  const create = async () => {
    setInProgress(true);
    const requests = [];
    requests.push(
      apiFetch("players", {
        method: "POST",
        body: JSON.stringify({
          gamer_tag: gamerTag,
          twitch_username: twitchUsername,
        }),
      })
    );
    try {
      await Promise.all(requests);
      update();
      showSnackbar(`Player created successfully.`, "success");
      dismiss();
    } catch (error) {
      if (error.errors[0].status === 400) {
        setValidationErrorText(error.errors[0].message);
      }
      showSnackbar("An error occurred. Please try again.", "error");
    }
    setInProgress(false);
  };

  // render

  return (
    <Dialog open={open}>
      <DialogTitle>Add player</DialogTitle>
      <DialogContent>
        <div className={styles.Content}>
          <TextField
            label="Gamer tag"
            value={gamerTag}
            onChange={handleGamerTagChange}
            className={styles.Input}
            required
            autoFocus
          />
          <TextField
            label="Twitch username"
            value={twitchUsername}
            onChange={handleTwitchUsernameChange}
            className={styles.Input}
            required
          />
        </div>
        {validationError}
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={dismiss}>
          Cancel
        </Button>
        <Button color="primary" disabled={inProgress} onClick={create}>
          Add
        </Button>
      </DialogActions>
      <LinearProgress
        className={styles.Progress + (inProgress ? "" : ` ${styles.isHidden}`)}
      />
    </Dialog>
  );
};

export default AddPlayerDialog;
