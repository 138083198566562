export const virusScoreToScore = (virusScore, levelStart = 0) => {
  if (!virusScore) {
    return 0;
  }
  const [level, virusesRemaining] = splitVirusScore(virusScore);
  return Math.max(
    0,
    (level + 1) * (level + 2) * 2 -
      virusesRemaining -
      levelStart * (levelStart + 1) * 2
  );
};

const splitVirusScore = (virusScore) => {
  return virusScore
    .replace(/[()]/, ":")
    .split(":")
    .map((part) => parseInt(part));
};

export const scoreToVirusScore = (score, shouldPad = false, levelStart = 0) => {
  if (!score) {
    return "";
  }
  let totalScore = 0;
  let level = levelStart;
  for (; level < 20; ++level) {
    totalScore += (level + 1) * 4;
    if (totalScore > score) break;
  }
  const padLength = shouldPad ? 2 : 0;
  return `${("" + level).padStart(padLength, "0")}(${(
    "" +
    (totalScore - score)
  ).padStart(padLength, "0")})`;
};

export const isValidVirusScore = (virusScore) => {
  if (virusScore === "") {
    return true;
  }
  if (!virusScore.match(/\d{2}\(\d{2}\)/)) {
    return false;
  }
  const [level, virusesRemaining] = splitVirusScore(virusScore);
  return (
    level >= 0 &&
    level <= 20 &&
    virusesRemaining >= 0 &&
    virusesRemaining <= (level + 1) * 4
  );
};
