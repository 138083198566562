export const timeToSeconds = (time) => {
  if (!time) {
    return 0;
  }
  const [minutes, seconds] = time.split(":").map((part) => parseInt(part));
  return minutes * 60 + seconds;
};

export const secondsToTime = (seconds, shouldPad = false) => {
  if (!seconds) {
    return "";
  }
  const padLength = shouldPad ? 2 : 0;
  return `${("" + Math.floor(seconds / 60)).padStart(padLength, "0")}:${(
    "" + Math.floor(seconds % 60)
  ).padStart(2, "0")}`;
};

export const isValidTime = (time) => {
  return time === "" || time.match(/\d{2}:\d{2}/);
};
