import React from "react";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Image from "./Image";
import apiFetch from "./utils/apiFetch";
import { secondsToTime } from "./utils/time";

import styles from "./HotTimes.module.scss";

class Record {
  constructor(event_id, player_id, seconds) {
    this.event_id = event_id;
    this.player_id = player_id;
    this.seconds = seconds;
  }
}

class HotTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      playerMap: {},
      eventMap: {},
      currentYear: 0,
      possibleYears: new Set(),
      currentRound: 0,
      possibleRounds: new Map(),
      isLoaded: false,
    };
  }

  loadHotTimes(currentYear, currentRound) {
    Promise.all([
      apiFetch("events"),
      apiFetch("rounds"),
      apiFetch("matches"),
      apiFetch("games"),
      apiFetch("players"),
    ]).then(
      ([
        eventsResponse,
        roundsResponse,
        matchesResponse,
        gamesResponse,
        playersResponse,
      ]) => {
        const possibleYears = new Set(
          eventsResponse.map((event) =>
            event.start_date.replace(/([0-9]{4}).*/, "$1")
          )
        );
        if (currentYear === null) {
          currentYear = "0";
        }
        const eventMap = new Map(
          eventsResponse
            .filter(
              (event) => currentYear === "0" || event.name.includes(currentYear)
            )
            .map((event) => [event.id, event.name])
        );
        const playerMap = new Map(
          playersResponse.map((player) => [player.id, player])
        );
        if (currentRound === null) {
          currentRound = Math.min(
            ...roundsResponse.map((round) => round.order)
          );
        } else {
          currentRound = parseInt(currentRound);
        }
        const possibleRounds = new Map(
          roundsResponse.map((round) => [round.order, round.name])
        );
        const roundMap = new Map(
          roundsResponse
            .filter(
              (round) =>
                eventMap.has(round.event_id) && round.order === currentRound
            )
            .map((round) => [round.id, round])
        );
        const matchMap = new Map(
          matchesResponse
            .filter((match) => roundMap.has(match.round_id))
            .map((match) => [match.id, match])
        );

        let records = [];
        for (let game of gamesResponse.filter((game) =>
          matchMap.has(game.match_id)
        )) {
          const match = matchMap.get(game.match_id);
          const round = roundMap.get(match.round_id);

          if (game.player_1_time_seconds) {
            records.push(
              new Record(
                round.event_id,
                match.player_1_id,
                game.player_1_time_seconds
              )
            );
          }
          if (game.player_2_time_seconds) {
            records.push(
              new Record(
                round.event_id,
                match.player_2_id,
                game.player_2_time_seconds
              )
            );
          }
        }

        this.setState({
          records: records.sort((a, b) => a.seconds - b.seconds).slice(0, 20),
          playerMap,
          eventMap,
          currentYear,
          possibleYears,
          currentRound,
          possibleRounds,
          isLoaded: true,
        });
      }
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.loadHotTimes(params.get("year"), params.get("round"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      this.loadHotTimes(params.get("year"), params.get("round"));
    }
  }

  render() {
    const {
      records,
      playerMap,
      eventMap,
      currentYear,
      possibleYears,
      currentRound,
      possibleRounds,
      isLoaded,
    } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let rank = 1;
      return (
        <div className={styles.RecordsContainer}>
          Year:{" "}
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(
                `/hottimes?year=${event.target.value}&round=${currentRound}`
              );
            }}
          >
            <MenuItem value={0}>All</MenuItem>
            {Array.from(possibleYears)
              .sort(function (a, b) {
                return b - a;
              })
              .map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>;
              })}
          </Select>
          Round:{" "}
          <Select
            name="round"
            value={currentRound}
            onChange={(event) => {
              this.props.history.push(
                `/hottimes?year=${currentYear}&round=${event.target.value}`
              );
            }}
          >
            {Array.from(possibleRounds.keys())
              .sort(function (a, b) {
                return a - b;
              })
              .map((round) => {
                return (
                  <MenuItem value={round}>{possibleRounds.get(round)}</MenuItem>
                );
              })}
          </Select>
          <Typography variant="h3">
            {currentYear === "0" ? "" : currentYear}{" "}
            {possibleRounds.get(currentRound)} Hot Times
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Time</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record) => {
                  const player = playerMap.get(record.player_id);
                  const playerImage = player.twitch_user ? (
                    <Image
                      src={player.twitch_user.profile_image_url}
                      className={styles.PlayerImage}
                    />
                  ) : (
                    ""
                  );
                  const eventName = eventMap.get(record.event_id);
                  return (
                    <TableRow key={rank++}>
                      <TableCell align="right">{rank}</TableCell>
                      <TableCell align="right">
                        {secondsToTime(record.seconds)}
                      </TableCell>
                      <TableCell>
                        <div className={styles.PlayerInfo}>
                          {playerImage}
                          <Link to={"/players/" + record.player_id}>
                            {player.gamer_tag}
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.EventInfo}>
                          <Link to={"/events/" + record.event_id}>
                            {eventName}
                          </Link>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default HotTimes;
