import React from "react";

import apiFetch from "./utils/apiFetch";

class Logout extends React.Component {
  async componentDidMount() {
    const sessionId = window.localStorage.getItem("sessionId");
    window.localStorage.removeItem("sessionId");
    await apiFetch("sessions/invalidate", {
      headers: {
        Authorization: "Bearer " + sessionId,
      },
    }).then(() => {
      this.props.history.push("/");
    });
  }

  render() {
    return <></>;
  }
}

export default Logout;
