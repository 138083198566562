const getWinner = (match, round) => {
  if (round.order === 1 && match.player_1_id && !match.player_2_id) {
    return match.player_1_id;
  } else if (round.order === 1 && match.player_2_id && !match.player_1_id) {
    return match.player_2_id;
  }
  const player1GamesWon = match.games.filter(
    (game) =>
      game.winning_player_number_forced === 1 ||
      game.winning_player_number_forced === 0 && (
        game.player_2_time_seconds == null ||
        game.player_2_time_seconds === 0 ||
        (game.player_1_time_seconds != null &&
          game.player_1_time_seconds !== 0 &&
          game.player_1_time_seconds < game.player_2_time_seconds))
  );
  const player2GamesWon = match.games.filter(
    (game) =>
      game.winning_player_number_forced === 2 ||
      game.winning_player_number_forced === 0 && (
        game.player_1_time_seconds == null ||
        game.player_1_time_seconds === 0 ||
        (game.player_2_time_seconds != null &&
          game.player_2_time_seconds !== 0 &&
          game.player_2_time_seconds < game.player_1_time_seconds))
  );

  if (player1GamesWon.length >= round.num_wins_required) {
    return match.player_1_id;
  } else if (player2GamesWon.length >= round.num_wins_required) {
    return match.player_2_id;
  }
  return null;
};

export default getWinner;
