import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import AddPlayerDialog from "./AddPlayerDialog";
import { ComboBox } from "./ComboBox";
import apiFetch from "./utils/apiFetch";
import { VirusScoreTextField } from "./VirusScoreTextField";
import {
  virusScoreToScore,
  scoreToVirusScore,
  isValidVirusScore,
} from "./utils/virusScore";
import { TimeTextField } from "./TimeTextField";
import { timeToSeconds, secondsToTime, isValidTime } from "./utils/time";

import styles from "./QualifierDialog.module.scss";

const QualifierDialog = ({
  open,
  closeDialog,
  qualifyingAttempts,
  playerMap,
  event,
  updateQualifiers,
  showSnackbar,
  qualifyingAttempt,
}) => {
  // state

  const [validationErrorText, setValidationErrorText] = useState(""); // string
  const [inProgress, setInProgress] = useState(false); // bool
  const [playerId, setPlayerId] = useState(
    qualifyingAttempt ? qualifyingAttempt.player_id : null
  );
  const [score, setScore] = useState(
    scoreToVirusScore(
      qualifyingAttempt ? qualifyingAttempt.score : 0,
      true /* shouldPad */
    )
  );
  const [timeToLastLevelSeconds, setTimeToLastLevelSeconds] = useState(
    secondsToTime(
      qualifyingAttempt ? qualifyingAttempt.time_to_last_level_seconds : 0,
      true /* shouldPad */
    )
  );
  const [attempt, setAttempt] = useState(
    qualifyingAttempt ? qualifyingAttempt.attempt : 1
  );
  const [isAddPlayerDialogOpen, setIsAddPlayerDialogOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );

  // calculated state

  const validationError = validationErrorText ? (
    <Alert severity="error">{validationErrorText}</Alert>
  ) : (
    ""
  );
  const players = Array.from(playerMap.values()).sort((a, b) =>
    a.gamer_tag.localeCompare(b.gamer_tag)
  );

  // handlers

  const handlePlayerIdChange = (event, value) => {
    const newPlayerId = value ? value.id : null;
    setPlayerId(newPlayerId);
    const nextAttempt =
      qualifyingAttempts.filter(
        (qualifyingAttempt) => qualifyingAttempt.player_id === newPlayerId
      ).length + 1;
    if (nextAttempt > 2) {
      setValidationErrorText("Only 2 qualifying attempts are allowed.");
    } else {
      setValidationErrorText("");
    }
    setAttempt(nextAttempt);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleTimeToLastLevelSecondsChange = (event) => {
    setTimeToLastLevelSeconds(event.target.value);
  };

  const handleAttemptChange = (event) => {
    setAttempt(event.target.value);
  };

  const dismiss = () => {
    // reset values?
    closeDialog();
  };

  const update = async () => {
    if (!isValid()) {
      return;
    }
    setInProgress(true);
    const requests = [];
    requests.push(
      apiFetch(
        `qualifying-attempts/${qualifyingAttempt ? qualifyingAttempt.id : ""}`,
        {
          method: qualifyingAttempt ? "PUT" : "POST",
          body: JSON.stringify({
            event_id: event.id,
            player_id: playerId,
            score: virusScoreToScore(score),
            time_to_last_level_seconds: timeToSeconds(timeToLastLevelSeconds),
            attempt,
          }),
        }
      )
    );
    try {
      await Promise.all(requests);
      await updateQualifiers();
      showSnackbar(
        `Qualifier ${qualifyingAttempt ? "updated" : "created"} successfully.`,
        "success"
      );
      dismiss();
    } catch (error) {
      if (error.errors[0].status === 400) {
        setValidationErrorText(error.errors[0].message);
      }
      showSnackbar("An error occurred. Please try again.", "error");
      setInProgress(false);
    }
  };

  const openConfirmDeleteDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const dismissConfirmDeleteDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const deleteQualifyingAttempt = async () => {
    dismissConfirmDeleteDialog();
    setInProgress(true);
    try {
      await apiFetch(`qualifying-attempts/${qualifyingAttempt.id}`, {
        method: "DELETE",
      });
      await updateQualifiers();
      showSnackbar("Qualifier deleted successfully.", "success");
      dismiss();
    } catch (error) {
      showSnackbar("An error occurred. Please try again.", "error");
      setInProgress(false);
    }
  };

  const isValid = () => {
    if (playerId === null) {
      setValidationErrorText("Please select a player.");
      return false;
    }
    if (!isValidVirusScore(score)) {
      setValidationErrorText("Invalid value for score.");
      return false;
    }
    if (virusScoreToScore(score) === 0) {
      setValidationErrorText("Score is required.");
      return false;
    }
    if (!isValidTime(timeToLastLevelSeconds)) {
      setValidationErrorText("Invalid value for time to last level.");
      return false;
    }
    if (attempt > 2) {
      setValidationErrorText("Only 2 qualifying attempts are allowed.");
      return false;
    }
    return true;
  };

  // renderable elements

  const addPlayerDialog = isAddPlayerDialogOpen ? (
    <AddPlayerDialog
      open={isAddPlayerDialogOpen}
      update={updateQualifiers}
      closeDialog={() => setIsAddPlayerDialogOpen(false)}
      showSnackbar={showSnackbar}
    />
  ) : (
    ""
  );

  const addPlayerButton = !qualifyingAttempt ? (
    <Button
      className={styles.AddPlayerButton}
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={() => setIsAddPlayerDialogOpen(true)}
    >
      Add player
    </Button>
  ) : (
    ""
  );

  // render

  return (
    <>
      <Dialog open={open} className={styles.QualifierDialog}>
        <DialogContent>
          <div className={styles.Content}>
            <ComboBox
              options={players}
              optionLabelField="gamer_tag"
              className={styles.Input}
              label="Player"
              onChange={handlePlayerIdChange}
              disabled={!!qualifyingAttempt}
              autoFocus={true}
              value={players.find((player) => player.id === playerId) || null}
            />
            {addPlayerButton}
            {addPlayerDialog}
            <VirusScoreTextField
              label="Score"
              value={score}
              onChange={handleScoreChange}
              className={styles.Input}
            />
            <TimeTextField
              label="Time to last level"
              value={timeToLastLevelSeconds}
              onChange={handleTimeToLastLevelSecondsChange}
              className={styles.Input}
            />
            <TextField
              label="Attempt"
              value={attempt}
              onChange={handleAttemptChange}
              className={styles.Input}
              type="number"
              disabled={!!qualifyingAttempt}
            />
          </div>
          {validationError}
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={() => dismiss()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={inProgress}
            onClick={() => update()}
          >
            {qualifyingAttempt ? "Update" : "Add"}
          </Button>
        </DialogActions>
        {qualifyingAttempt && (
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              disabled={inProgress}
              onClick={openConfirmDeleteDialog}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </DialogActions>
        )}
        <LinearProgress
          className={
            styles.Progress + (inProgress ? "" : ` ${styles.isHidden}`)
          }
        />
      </Dialog>
      <Dialog open={isConfirmDeleteDialogOpen}>
        <DialogTitle>Really delete this qualifying attempt?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you really want to delete this qualifying attempt?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissConfirmDeleteDialog}>Cancel</Button>
          <Button onClick={deleteQualifyingAttempt} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QualifierDialog;
