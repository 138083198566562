import React from "react";

import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Image from "./Image";
import TwitchChip from "./TwitchChip";
import apiFetch from "./utils/apiFetch";
import { secondsToTime } from "./utils/time";
import { scoreToVirusScore } from "./utils/virusScore";
import getWinner from "./utils/getWinner";

import styles from "./Player.module.scss";

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: {},
      isLoaded: false,
      qualifiers: [],
      events: [],
      playerMap: {},
    };
  }

  componentDidMount() {
    this.updatePlayer();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.updatePlayer();
    }
  }

  updatePlayer() {
    Promise.all([
      apiFetch(`players/${this.props.match.params.id}`),
      apiFetch(`players/${this.props.match.params.id}/qualifying-attempts`),
      apiFetch("events"),
      apiFetch(`players/${this.props.match.params.id}/matches`),
      apiFetch("rounds"),
      apiFetch("seeds"),
      apiFetch("players"),
    ]).then(
      ([
        playerResponse,
        qualifyingAttemptsResponse,
        eventsResponse,
        matchesResponse,
        roundsResponse,
        seedsResponse,
        playersResponse,
      ]) => {
        Promise.all(
          matchesResponse.map((match) =>
            apiFetch(`matches/${match.id}/games`).then((gamesResponse) => ({
              match,
              games: gamesResponse,
            }))
          )
        ).then((gamesResponses) => {
          const eventMap = {};
          for (const event of eventsResponse) {
            eventMap[event.id] = event;
          }
          const qualifiers = [];
          for (const qualifyingAttempt of qualifyingAttemptsResponse.sort(
            (a, b) => {
              const eventA = eventMap[a.event_id];
              const eventB = eventMap[b.event_id];
              const diff = eventB.start_date.localeCompare(eventA.start_date);
              if (diff === 0) {
                return a.attempt - b.attempt;
              }
              return diff;
            }
          )) {
            qualifyingAttempt.event = eventMap[qualifyingAttempt.event_id];
            if (
              qualifiers.length > 0 &&
              qualifiers[qualifiers.length - 1].event_id ===
                qualifyingAttempt.event_id
            ) {
              qualifyingAttempt.lastQualifyingAttempt =
                qualifiers[qualifiers.length - 1];
              qualifiers[qualifiers.length - 1] = qualifyingAttempt;
            } else {
              qualifiers.push(qualifyingAttempt);
            }
          }
          const roundMap = {};
          for (const round of roundsResponse) {
            roundMap[round.id] = round;
          }
          const playerMap = {};
          for (const player of playersResponse) {
            playerMap[player.id] = player;
          }
          const gameMap = {};
          for (const gamesResponse of gamesResponses) {
            gameMap[gamesResponse.match.id] = gamesResponse.games;
          }
          const seedMap = {};
          for (const seed of seedsResponse) {
            if (!seedMap[seed.event_id]) {
              seedMap[seed.event_id] = {};
            }
            seedMap[seed.event_id][seed.player_id] = seed;
          }
          const events = [];
          for (const match of matchesResponse.sort((a, b) => {
            const roundA = roundMap[a.round_id];
            const roundB = roundMap[b.round_id];
            const eventA = eventMap[roundA.event_id];
            const eventB = eventMap[roundB.event_id];
            const eventDiff = eventB.start_date.localeCompare(
              eventA.start_date
            );
            if (eventDiff !== 0) {
              return eventDiff;
            }
            return roundA.order - roundB.order;
          })) {
            match.round = roundMap[match.round_id];
            match.event = eventMap[match.round.event_id];
            const opponent =
              playerMap[
                match.player_1_id === playerResponse.id
                  ? match.player_2_id
                  : match.player_1_id
              ];
            match.opponent = opponent ? Object.assign({}, opponent) : null;
            match.games = gameMap[match.id];
            match.winner = getWinner(match, match.round);
            match.numGamesWon = match.games.filter((game) =>
              match.player_1_id === playerResponse.id
                ? game.player_2_time_seconds == null ||
                  game.player_2_time_seconds === 0 ||
                  (game.player_1_time_seconds != null &&
                    game.player_1_time_seconds !== 0 &&
                    game.player_1_time_seconds < game.player_2_time_seconds)
                : game.player_1_time_seconds == null ||
                  game.player_1_time_seconds === 0 ||
                  (game.player_2_time_seconds != null &&
                    game.player_2_time_seconds !== 0 &&
                    game.player_2_time_seconds < game.player_1_time_seconds)
            ).length;
            match.seed = seedMap[match.event.id][playerResponse.id];
            if (match.opponent) {
              match.opponent.numGamesWon = match.games.filter((game) =>
                match.player_1_id !== playerResponse.id
                  ? game.player_2_time_seconds == null ||
                    game.player_2_time_seconds === 0 ||
                    (game.player_1_time_seconds != null &&
                      game.player_1_time_seconds !== 0 &&
                      game.player_1_time_seconds < game.player_2_time_seconds)
                  : game.player_1_time_seconds == null ||
                    game.player_1_time_seconds === 0 ||
                    (game.player_2_time_seconds != null &&
                      game.player_2_time_seconds !== 0 &&
                      game.player_2_time_seconds < game.player_1_time_seconds)
              ).length;
              match.opponent.seed = seedMap[match.event.id][match.opponent.id];
            }
            if (
              events.length === 0 ||
              events[events.length - 1].id !== match.event.id
            ) {
              match.event.matches = [];
              events.push(match.event);
            }
            events[events.length - 1].matches.push(match);
          }
          this.setState({
            player: playerResponse,
            isLoaded: true,
            qualifiers,
            events,
          });
        });
      }
    );
  }

  render() {
    const { player, isLoaded, qualifiers, events } = this.state;
    const profilePicture = player.twitch_user ? (
      <Image
        src={player.twitch_user.profile_image_url}
        className={styles.ProfileImage}
      />
    ) : (
      ""
    );
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className={styles.PlayerContainer}>
          <div className={styles.Title}>
            {profilePicture}
            <Typography variant="h4">{player.gamer_tag}</Typography>
            <TwitchChip player={player} className={styles.PlayerTwitchChip} />
          </div>
          <Typography variant="h5" className={styles.SectionTitle}>
            History
          </Typography>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell>Matches</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>
                      <Link to={`/events/${event.id}`}>{event.name}</Link>
                    </TableCell>
                    <TableCell>
                      <div className={styles.Matches}>
                        {event.matches.map((match) => {
                          const opponentEl = match.opponent ? (
                            <>
                              <br />({match.seed.seed}) vs. (
                              {match.opponent.seed.seed}){" "}
                              <Link
                                to={`/players/${match.opponent.id}`}
                                className={styles.OpponentLink}
                              >
                                {match.opponent.gamer_tag}
                              </Link>
                            </>
                          ) : (
                            ""
                          );
                          return (
                            <div
                              key={match.id}
                              className={`${styles.Match}${
                                match.winner === null
                                  ? ""
                                  : " " +
                                    (match.winner === player.id
                                      ? styles.IsWinner
                                      : styles.IsLoser)
                              }`}
                            >
                              <div className={styles.RoundInfo}>
                                <span className={styles.RoundName}>
                                  {match.round.name}
                                </span>
                                {opponentEl}
                              </div>
                              <div className={styles.MatchInfo}>
                                {match.winner === null
                                  ? ""
                                  : match.winner === player.id
                                  ? "W "
                                  : "L "}
                                {match.numGamesWon}-
                                {match.opponent
                                  ? match.opponent.numGamesWon
                                  : 0}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h5" className={styles.SectionTitle}>
            Qualifiers
          </Typography>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell align="center" colSpan="2">
                    Score
                  </TableCell>
                  <TableCell align="right">Time to Last Level</TableCell>
                  <TableCell align="right">Previous Attempt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {qualifiers.map((qualifier) => (
                  <TableRow key={qualifier.id}>
                    <TableCell>
                      <Link to={`/events/${qualifier.event_id}`}>
                        {qualifier.event.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {scoreToVirusScore(qualifier.score)}
                    </TableCell>
                    <TableCell align="left">{qualifier.score}</TableCell>
                    <TableCell align="right">
                      {secondsToTime(qualifier.time_to_last_level_seconds)}
                    </TableCell>
                    <TableCell align="right">
                      {qualifier.lastQualifyingAttempt
                        ? scoreToVirusScore(
                            qualifier.lastQualifyingAttempt.score
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default Player;
