import React, { useState } from "react";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import apiFetch from "./utils/apiFetch";
import { BRACKET_INDEX } from "./Bracket";

import styles from "./BracketDialog.module.scss";

const BRACKET_SIZE_OPTIONS = [2, 4, 8, 16];
const BYE_VALUE = "Bye";

const BracketDialog = ({
  open,
  setOpen,
  qualifiers,
  seeds,
  rounds,
  playerMap,
  eventId,
  updateEvent,
  showSnackbar,
}) => {
  // state

  const [validationErrorText, setValidationErrorText] = useState(""); // string
  const [inProgress, setInProgress] = useState(false); // bool
  const [bracketSize, setBracketSize] = useState(
    getDefaultBracketSize(qualifiers)
  ); // int
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );

  // calculated state

  const numRounds = Math.log2(bracketSize);
  const validationError = validationErrorText ? (
    <Alert severity="error">{validationErrorText}</Alert>
  ) : (
    ""
  );
  const players = qualifiers.map((qualifier) =>
    playerMap.get(qualifier.player_id)
  );
  const isUpdate = seeds.length > 0;

  // uncontrolled component refs

  const seedInputs = [...Array(bracketSize)].map((_) => React.createRef());
  const roundNameInputs = [...Array(numRounds)].map((_) => React.createRef());
  const roundWinsRequiredInputs = [...Array(numRounds)].map((_) =>
    React.createRef()
  );
  const roundLevelStartInputs = [...Array(numRounds)].map((_) =>
    React.createRef()
  );
  const roundLevelEndInputs = [...Array(numRounds)].map((_) =>
    React.createRef()
  );

  // handlers

  const handleBracketSizeChange = (event) => {
    setBracketSize(event.target.value);
  };

  const dismiss = () => {
    // reset values?
    setOpen(false);
  };

  const mutate = async () => {
    const newRounds = [];
    const newSeeds = [];
    const matches = [];
    for (let i = 0; i < numRounds; i++) {
      const newRound = {
        name: roundNameInputs[i].current.querySelector("input").value,
        order: i + 1,
        num_wins_required: roundWinsRequiredInputs[i].current.querySelector(
          "input"
        ).value,
        level_start: roundLevelStartInputs[i].current.querySelector("input")
          .value,
        level_end: roundLevelEndInputs[i].current.querySelector("input").value,
      };
      if (isUpdate) {
        newRound.id = rounds[i].id;
      }
      newRounds.push(newRound);
    }
    seedInputs.forEach((seedInput, i) => {
      const playerId = seedInput.current.querySelector("input").value;
      const newSeed = {
        player_id: playerId === BYE_VALUE ? null : playerId,
        seed: i + 1,
      };
      if (isUpdate) {
        newSeed.id = seeds[i].id;
      }
      newSeeds.push(newSeed);
      if (!isUpdate && i < bracketSize / 2) {
        const player1Id = playerId;
        const player2Id = seedInputs[bracketSize - i - 1].current.querySelector(
          "input"
        ).value;
        matches.push({
          round_order: 1,
          player_1_id: player1Id === BYE_VALUE ? null : player1Id,
          player_2_id: player2Id === BYE_VALUE ? null : player2Id,
          match_number: BRACKET_INDEX[bracketSize][i] + 1,
        });
      }
    });
    if (!isUpdate) {
      for (let roundNum = 2; roundNum <= newRounds.length; roundNum++) {
        const numMatches = bracketSize / Math.pow(2, roundNum);
        [...Array(numMatches)].forEach(() => {
          matches.push({
            round_order: roundNum,
            player_1_id: null,
            player_2_id: null,
            match_number: matches.length + 1,
          });
        });
      }
    }
    setInProgress(true);
    try {
      await apiFetch(`brackets${isUpdate ? "/" + eventId : ""}`, {
        method: isUpdate ? "PUT" : "POST",
        body: JSON.stringify({
          event_id: eventId,
          rounds: newRounds,
          seeds: newSeeds,
          matches,
        }),
      });
      updateEvent();
      showSnackbar(
        `Bracket ${isUpdate ? "updated" : "created"} successfully.`,
        "success"
      );
      dismiss();
    } catch (error) {
      showSnackbar("An error occurred. Please try again.", "error");
      setInProgress(false);
    }
  };

  const openConfirmDeleteDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const dismissConfirmDeleteDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const deleteBracket = async () => {
    dismissConfirmDeleteDialog();
    setInProgress(true);
    try {
      await apiFetch(`brackets/${eventId}`, {
        method: "DELETE",
      });
      await updateEvent();
      showSnackbar("Bracket deleted successfully.", "success");
      dismiss();
    } catch (error) {
      showSnackbar("An error occurred. Please try again.", "error");
      setInProgress(false);
    }
  };

  // render

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="bracket-dialog-title"
        className={styles.BracketDialog}
      >
        <DialogTitle id="bracket-dialog-title">
          {isUpdate ? "Edit" : "Create"} bracket
        </DialogTitle>
        <DialogContent>
          <div className={styles.Content}>
            <FormControl className={styles.Input}>
              <InputLabel id="bracket-bracket-size">Bracket size</InputLabel>
              <Select
                labelId="bracket-bracket-size"
                value={bracketSize}
                onChange={handleBracketSizeChange}
                disabled={isUpdate}
              >
                {BRACKET_SIZE_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={styles.Grid}>
              <div className={styles.Seeds}>
                {[...Array(bracketSize).keys()].map((seed) => (
                  <FormControl key={seed} className={styles.Input}>
                    <InputLabel id={`bracket-seed-${seed}`}>
                      Seed {seed + 1}
                    </InputLabel>
                    <Select
                      ref={seedInputs[seed]}
                      labelId={`bracket-seed-${seed}`}
                      defaultValue={
                        seeds && seeds[seed]
                          ? seeds[seed].player_id
                            ? seeds[seed].player_id
                            : BYE_VALUE
                          : qualifiers[seed]
                          ? qualifiers[seed].player_id
                          : BYE_VALUE
                      }
                    >
                      <MenuItem value={BYE_VALUE}>Select player</MenuItem>
                      {players.map((player) => (
                        <MenuItem key={player.id} value={player.id}>
                          {player.gamer_tag}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
              </div>
              <div className={styles.Rounds}>
                {[...Array(numRounds).keys()].map((round) => (
                  <div
                    key={round}
                    className={styles.Round}
                    data-name={`Round ${round + 1}`}
                  >
                    <TextField
                      ref={roundNameInputs[round]}
                      label="Name"
                      defaultValue={
                        rounds && rounds[round]
                          ? rounds[round].name
                          : getDefaultRoundName(round, numRounds)
                      }
                      className={styles.Input}
                    />
                    <TextField
                      ref={roundWinsRequiredInputs[round]}
                      label="Wins required"
                      defaultValue={
                        rounds && rounds[round]
                          ? rounds[round].num_wins_required
                          : getDefaultRoundWinsRequired(round, numRounds)
                      }
                      className={styles.Input}
                      type="number"
                    />
                    <div className={styles.LevelInputs}>
                      <TextField
                        ref={roundLevelStartInputs[round]}
                        label="Start level"
                        defaultValue={
                          rounds && rounds[round]
                            ? rounds[round].level_start
                            : getDefaultLevelStart(round, numRounds)
                        }
                        className={styles.Input}
                        type="number"
                      />
                      <TextField
                        ref={roundLevelEndInputs[round]}
                        label="End level"
                        defaultValue={
                          rounds && rounds[round]
                            ? rounds[round].level_end
                            : getDefaultLevelEnd(round, numRounds)
                        }
                        className={styles.Input}
                        type="number"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {validationError}
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={() => dismiss()}>
            Cancel
          </Button>
          <Button color="primary" disabled={inProgress} onClick={mutate}>
            {isUpdate ? "Update" : "Create"}
          </Button>
        </DialogActions>
        {isUpdate && (
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              disabled={inProgress}
              onClick={openConfirmDeleteDialog}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </DialogActions>
        )}
        <LinearProgress
          className={
            styles.Progress + (inProgress ? "" : ` ${styles.isHidden}`)
          }
        />
      </Dialog>
      <Dialog open={isConfirmDeleteDialogOpen}>
        <DialogTitle>Really delete this qualifying attempt?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you really want to delete this bracket?
            <Alert severity="error">
              THIS WILL DELETE ALL MATCHES AND GAMES FOR THIS EVENT
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissConfirmDeleteDialog}>Cancel</Button>
          <Button onClick={deleteBracket} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getDefaultRoundName = (round, numRounds) => {
  if (round === numRounds - 1) {
    return "Finals";
  }
  if (round === numRounds - 2) {
    return "Semifinals";
  }
  return `Round ${round + 1}`;
};

const getDefaultRoundWinsRequired = (round, numRounds) => {
  if (round >= numRounds - 2) {
    return 3;
  }
  return 2;
};

const getDefaultLevelStart = (round, numRounds) => {
  switch (numRounds - round) {
    case 1:
      return 13;
    case 2:
      return 10;
    case 3:
      return 8;
    case 4:
      return 6;
  }
  return 0;
};

const getDefaultLevelEnd = (round, numRounds) => {
  switch (numRounds - round) {
    case 1:
      return 17;
    case 2:
      return 14;
    case 3:
      return 11;
    case 4:
      return 9;
  }
  return 0;
};

const getDefaultBracketSize = (qualifiers) => {
  return BRACKET_SIZE_OPTIONS.reduce((prev, curr) =>
    qualifiers.length <= prev ? prev : curr
  );
};

export default BracketDialog;
