import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Image from "./Image";
import apiFetch from "./utils/apiFetch";
import { scoreToVirusScore } from "./utils/virusScore";

import styles from "./QualRecords.module.scss";

class QualRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quals: [],
      playerMap: {},
      eventMap: {},
      isLoaded: false,
    };
  }

  componentDidMount() {
    Promise.all([
      apiFetch("qualifying-attempts"),
      apiFetch("events"),
      apiFetch("players"),
    ]).then(([qualsResponse, eventsResponse, playersResponse]) => {
      const playerMap = new Map(
        playersResponse.map((player) => [player.id, player])
      );
      const eventMap = new Map(
        eventsResponse.map((event) => [event.id, event.name])
      );
      const qualMap = new Map();
      qualsResponse.forEach((qual) => {
        let key = [qual.event_id, qual.player_id];
        if (!(key in qualMap) || qual.attempt > qualMap[key].attempt) {
          qual.score_notation = scoreToVirusScore(qual.score);
          qualMap.set(key, qual);
        }
      });
      let quals = Array.from(qualMap.values());
      this.setState({
        quals,
        playerMap,
        eventMap,
        isLoaded: true,
      });
    });
  }

  render() {
    const { quals, playerMap, eventMap, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let rank = 1;
      return (
        <div className={styles.RecordsContainer}>
          <Typography variant="h3">Top Qualifying Scores</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Score</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quals
                  .sort(function (a, b) {
                    return b.score - a.score;
                  })
                  .slice(0, 20)
                  .map((qual) => {
                    const player = playerMap.get(qual.player_id);
                    const playerImage = player.twitch_user ? (
                      <Image
                        src={player.twitch_user.profile_image_url}
                        className={styles.PlayerImage}
                      />
                    ) : (
                      ""
                    );
                    const eventName = eventMap.get(qual.event_id);
                    return (
                      <TableRow key={qual.id}>
                        <TableCell align="right">{rank++}</TableCell>
                        <TableCell align="right">
                          {qual.score_notation} = {qual.score}
                        </TableCell>
                        <TableCell>
                          <div className={styles.PlayerInfo}>
                            {playerImage}
                            <Link to={"/players/" + qual.player_id}>
                              {player.gamer_tag}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.EventInfo}>
                            <Link to={"/events/" + qual.event_id}>
                              {eventName}
                            </Link>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default QualRecord;
