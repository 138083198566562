import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { Link, Redirect } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import apiFetch from "./utils/apiFetch";

import styles from "./AdminEdit.module.scss";

const ColumnTypes = {
  STRING: 0,
  TIMESTAMP: 1,
};

const TABLES = {
  events: {
    columns: {
      name: { type: ColumnTypes.STRING, required: true },
      start_date: { type: ColumnTypes.TIMESTAMP, required: true },
    },
    defaultOrder: {
      column: "start_date",
      ascending: false,
    },
    displayNameColumn: "name",
  },
  players: {
    columns: {
      gamer_tag: { type: ColumnTypes.STRING, required: true },
      twitch_username: { type: ColumnTypes.STRING, required: true },
    },
    defaultOrder: {
      column: "gamer_tag",
      ascending: true,
    },
    displayNameColumn: "gamer_tag",
  },
};

const DATE_FORMAT = "LLL d, yyyy";

const getDisplayName = (name) => {
  return name
    .split("_")
    .map((string) => string.slice(0, 1).toUpperCase() + string.slice(1))
    .join(" ");
};

const getEntityDisplayName = (entity, table) => {
  return entity
    ? table.displayNameColumn
      ? entity[table.displayNameColumn]
      : entity.id
    : "";
};

const formatValueForDisplay = (value, type) => {
  switch (type) {
    case ColumnTypes.TIMESTAMP:
      return DateTime.fromISO(value).setZone("UTC").toFormat(DATE_FORMAT);
    default:
      return value;
  }
};

const getValue = (value, type) => {
  switch (type) {
    default:
      return value;
  }
};

const transformValue = (value, type) => {
  switch (type) {
    case ColumnTypes.TIMESTAMP:
      return DateTime.fromISO(value).setZone("UTC").toISODate();
    default:
      return value;
  }
};

const AdminEdit = (props) => {
  const tableName = props.match.params.table;
  const tableDisplayName = getDisplayName(tableName);
  const table = TABLES[tableName];
  const tableExists = table !== undefined;
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    let isMounted = true;
    async function fetchEntities() {
      const entities = await apiFetch(tableName);
      if (isMounted) {
        setEntities(
          entities.sort((a, b) =>
            (table.defaultOrder.ascending
              ? a[table.defaultOrder.column]
              : b[table.defaultOrder.column]
            ).localeCompare(
              table.defaultOrder.ascending
                ? b[table.defaultOrder.column]
                : a[table.defaultOrder.column]
            )
          )
        );
        setIsLoading(false);
      }
    }
    fetchEntities();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const [currentEntity, setCurrentEntity] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [createInProgress, setCreateInProgress] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [updateEntityDisplayName, setUpdateEntityDisplayName] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  const updateEntity = (updateEntity) => {
    const transformedEntity = transformEntity(updateEntity);
    setCurrentEntity(transformedEntity);
    setValidationError("");
    setUpdateEntityDisplayName(getEntityDisplayName(transformedEntity, table));
    setIsUpdating(true);
  };

  const transformEntity = (entity) => {
    const transformed = {};
    for (let [key, value] of Object.entries(entity)) {
      const column = table.columns[key];
      if (column) {
        value = transformValue(value, column.type);
      }
      transformed[key] = value;
    }
    return transformed;
  };

  const dismissUpdateEntity = () => {
    setIsUpdating(false);
  };

  const confirmUpdateEntity = async (updateEntity) => {
    if (!isEntityValid(updateEntity)) {
      setValidationError(
        "Please check and make sure all required fields are completed."
      );
      return;
    }
    setValidationError("");
    setUpdateInProgress(true);
    try {
      await apiFetch(`${tableName}/${updateEntity.id}`, {
        method: "PUT",
        body: JSON.stringify(updateEntity),
      });
      setIsUpdating(false);
      setUpdateInProgress(false);
      setRefreshCounter(refreshCounter + 1);
    } catch (error) {
      setIsUpdating(false);
      setUpdateInProgress(false);
      setError("Error encountered while trying to update.");
      setIsError(true);
    }
  };

  const deleteEntity = (deleteEntity) => {
    setCurrentEntity(deleteEntity);
    setIsDeleting(true);
  };

  const dismissDeleteEntity = () => {
    setIsDeleting(false);
  };

  const confirmDeleteEntity = async (deleteEntity) => {
    setDeleteInProgress(true);
    try {
      await apiFetch(`${tableName}/${deleteEntity.id}`, {
        method: "DELETE",
      });
      setIsDeleting(false);
      setDeleteInProgress(false);
      setRefreshCounter(refreshCounter + 1);
    } catch (error) {
      setIsDeleting(false);
      setDeleteInProgress(false);
      setError("Error encountered while trying to delete.");
      setIsError(true);
    }
  };

  const createEntity = () => {
    setCurrentEntity({});
    setValidationError("");
    setIsCreating(true);
  };

  const dismissCreateEntity = () => {
    setIsCreating(false);
  };

  const confirmCreateEntity = async () => {
    if (!isEntityValid(currentEntity)) {
      setValidationError(
        "Please check and make sure all required fields are completed."
      );
      return;
    }
    setValidationError("");
    setCreateInProgress(true);
    try {
      await apiFetch(tableName, {
        method: "POST",
        body: JSON.stringify(currentEntity),
      });
      setIsCreating(false);
      setCreateInProgress(false);
      setRefreshCounter(refreshCounter + 1);
    } catch (error) {
      setIsCreating(false);
      setCreateInProgress(false);
      setError("Error encountered while trying to create.");
      setIsError(true);
    }
  };

  const isEntityValid = (entity) => {
    const invalidFields = Object.entries(table.columns).filter(
      ([name, column]) =>
        column.required &&
        (entity[name] == null || ("" + entity[name]).trim() === "")
    );
    return invalidFields.length === 0;
  };

  const confirmError = () => {
    setIsError(false);
  };

  const renderInput = (
    { type, name, required },
    value,
    shouldAutoFocus = false
  ) => {
    const label = getDisplayName(name);
    switch (type) {
      case ColumnTypes.TIMESTAMP:
        return (
          <DatePicker
            className={styles.Input}
            label={label + (required ? " *" : "")}
            value={value ? DateTime.fromISO(value).setZone("UTC") : null}
            format={DATE_FORMAT}
            onChange={(dateTime) => {
              const entity = Object.assign({}, currentEntity);
              entity[name] = dateTime.toISODate();
              setCurrentEntity(entity);
            }}
          />
        );
      case ColumnTypes.STRING:
      default:
        return (
          <TextField
            className={styles.Input}
            label={label}
            variant="outlined"
            onChange={(event) => {
              const entity = Object.assign({}, currentEntity);
              entity[name] = event.target.value;
              setCurrentEntity(entity);
            }}
            defaultValue={value}
            autoFocus={shouldAutoFocus}
            required={!!required}
          />
        );
    }
  };

  const currentEntityDisplayName = getEntityDisplayName(currentEntity, table);

  return tableExists ? (
    <div className={styles.AdminEditContainer}>
      <Typography variant="h3">{tableDisplayName}</Typography>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(table.columns).map((name, index) => (
                    <TableCell key={index}>{getDisplayName(name)}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {entities.map((entity) => (
                  <TableRow key={entity.id} className={styles.TableRow}>
                    {Object.entries(table.columns).map(
                      ([name, column], index) => {
                        const value = formatValueForDisplay(
                          entity[name],
                          column.type
                        );
                        const displayValue =
                          table.displayNameColumn === name ? (
                            <Link to={`/${tableName}/${entity.id}`}>
                              {value}
                            </Link>
                          ) : (
                            value
                          );
                        return (
                          <TableCell key={index}>{displayValue}</TableCell>
                        );
                      }
                    )}
                    <TableCell align="right" className={styles.ActionsCell}>
                      <IconButton
                        onClick={() => updateEntity(Object.assign({}, entity))}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteEntity(Object.assign({}, entity))}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Fab
            color="primary"
            aria-label="add"
            onClick={() => createEntity()}
            className={styles.Fab}
          >
            <Add />
          </Fab>

          <Dialog open={isCreating} aria-labelledby="alert-create-dialog-title">
            <DialogTitle id="alert-create-dialog-title">
              Create new <strong>{tableDisplayName}</strong> entry
            </DialogTitle>
            <DialogContent>
              <div className={styles.AdminEditForm}>
                {Object.entries(table.columns).map(([name, column], i) => (
                  <React.Fragment key={name}>
                    {renderInput(
                      { name, ...column },
                      getValue(currentEntity[name], column.type),
                      i === 0
                    )}
                  </React.Fragment>
                ))}
                {validationError ? (
                  <Alert severity="error">{validationError}</Alert>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={createInProgress}
                onClick={() => dismissCreateEntity()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={createInProgress}
                onClick={() => confirmCreateEntity()}
              >
                OK
              </Button>
            </DialogActions>
            <LinearProgress
              className={
                "AdminEditProgress" + (createInProgress ? "" : " isHidden")
              }
            />
          </Dialog>

          <Dialog open={isUpdating} aria-labelledby="alert-update-dialog-title">
            <DialogTitle id="alert-update-dialog-title">
              Update `{updateEntityDisplayName}`
            </DialogTitle>
            <DialogContent>
              <div className={styles.AdminEditForm}>
                {Object.entries(table.columns).map(([name, column], i) => (
                  <React.Fragment key={name}>
                    {renderInput(
                      { name, ...column },
                      getValue(currentEntity[name], column.type),
                      i === 0
                    )}
                  </React.Fragment>
                ))}
                {validationError ? (
                  <Alert severity="error">{validationError}</Alert>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={updateInProgress}
                onClick={() => dismissUpdateEntity()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={updateInProgress}
                onClick={() => confirmUpdateEntity(currentEntity)}
              >
                OK
              </Button>
            </DialogActions>
            <LinearProgress
              className={
                styles.AdminEditProgress +
                (updateInProgress ? "" : ` ${styles.isHidden}`)
              }
            />
          </Dialog>

          <Dialog
            open={isDeleting}
            aria-labelledby="alert-delete-dialog-title"
            aria-describedby="alert-delete-dialog-description"
          >
            <DialogTitle id="alert-delete-dialog-title">
              Delete `{currentEntityDisplayName}`?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-delete-dialog-description">
                Are you sure you want to delete `{currentEntityDisplayName}`?
                This action is undoable.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={deleteInProgress}
                onClick={() => dismissDeleteEntity()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={deleteInProgress}
                autoFocus
                onClick={() => confirmDeleteEntity(currentEntity)}
              >
                OK
              </Button>
            </DialogActions>
            <LinearProgress
              className={
                styles.AdminEditProgress +
                (deleteInProgress ? "" : ` ${styles.isHidden}`)
              }
            />
          </Dialog>

          <Dialog
            open={isError}
            aria-labelledby="alert-error-dialog-title"
            aria-describedby="alert-error-dialog-description"
          >
            <DialogTitle id="alert-error-dialog-title">Error</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-error-dialog-description">
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" autoFocus onClick={() => confirmError()}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  ) : (
    <Redirect
      to={{
        pathname: "/admin",
        state: { notification: `The ${tableName} table doesn't exist.` },
      }}
    />
  );
};

export default AdminEdit;
