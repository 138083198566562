import React, { useLayoutEffect, useState } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TimerIcon from "@material-ui/icons/Timer";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import EventIcon from "@material-ui/icons/Event";
import GavelIcon from "@material-ui/icons/Gavel";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import { AppBar, Box, Snackbar, Toolbar, Typography } from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/core/styles";

import Admin from "./Admin";
import AdminButton from "./AdminButton";
import AdminEdit from "./AdminEdit";
import AdminRoute from "./AdminRoute";
import Event from "./Event";
import Events from "./Events";
import IfAdmin from "./IfAdmin";
import Image from "./Image";
import LoginButton from "./LoginButton";
import Logout from "./Logout";
import Player from "./Player";
import QualRecords from "./QualRecords";
import Standings from "./Standings";
import HotTimes from "./HotTimes";
import Session from "./Session";
import apiFetch from "./utils/apiFetch";

import styles from "./App.module.scss";

import { ReactComponent as DiscordIcon } from "./discord.svg";

const MENU_ITEMS = [
  {
    icon: <EventIcon />,
    label: "Events",
    href: "/",
  },
  {
    icon: <EmojiEventsIcon />,
    label: "Standings",
    href: "/standings",
  },
  {},
  {
    icon: <TimerIcon />,
    label: "Qualifiers",
    href: "/qualrecords",
  },
  {
    icon: <WhatshotIcon />,
    label: "Hot Times",
    href: "/hottimes",
  },
  {},
  {
    // icon: <ForumIcon />,
    icon: (
      <SvgIcon>
        <DiscordIcon />
      </SvgIcon>
    ),
    label: "Discord",
    href: "https://discord.gg/fdEJXzT",
  },
  {
    icon: <GavelIcon />,
    label: "Rules",
    href:
      "https://docs.google.com/document/d/1EmqKIl_8mqhdMdT3dLO313KEyRW5Oo1vt64iYtW5zqc/edit?usp=sharing",
  },
];

const App = (props) => {
  const [user, setUser] = useState(null);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();

  useLayoutEffect(() => {
    async function fetchUser() {
      const sessionId = window.localStorage.getItem("sessionId");
      let user = null;
      if (sessionId !== null && !isFetchingUser) {
        setIsFetchingUser(true);
        try {
          user = await apiFetch("user");
        } catch (error) {}
        setIsFetchingUser(false);
      }
      setUser(user);
      setHasFetchedUser(true);
    }
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const adminProps = { user, isFetchingUser, hasFetchedUser };

  const showSnackbar = (message, severity = "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <StylesProvider injectFirst>
        <Box>
          <AppBar position="static" className={styles.Header}>
            <Toolbar>
              <IconButton
                edge="start"
                className={styles.MenuButton}
                onClick={() => setDrawerOpen(true)}
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <div
                  className={styles.DrawerList}
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <List>
                    {MENU_ITEMS.map((menuItem, i) => {
                      if (!menuItem.label) {
                        return <Divider className={styles.Divider} key={i} />;
                      }
                      const isInternal = menuItem.href.startsWith("/");
                      const component = isInternal ? Link : "a";
                      const linkProps = isInternal
                        ? { to: menuItem.href }
                        : {
                            href: menuItem.href,
                            target: "_blank",
                            noreferrer: null,
                          };
                      return (
                        <ListItem
                          key={i}
                          button
                          component={component}
                          {...linkProps}
                        >
                          <ListItemIcon>{menuItem.icon}</ListItemIcon>
                          <ListItemText primary={menuItem.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </Drawer>
              <Link to="/" className={styles.TitleLink}>
                <Typography variant="h5" className={styles.Title}>
                  <Image src="/logo.png" className={styles.TitleImage} />
                  <span className={styles.TitleText}>Speed Monthly</span>
                </Typography>
              </Link>
              <IfAdmin user={user}>
                <AdminButton />
              </IfAdmin>
              <LoginButton user={user} />
            </Toolbar>
          </AppBar>
          <Box m={2}>
            <Switch>
              <Route path="/session" component={Session} />
              <Route path="/logout" component={Logout} />
              <Route path="/qualrecords" component={QualRecords} />
              <Route path="/standings/:year?" component={Standings} />
              <Route path="/hottimes/" component={HotTimes} />
              <Route
                path="/events/:id"
                render={(props) => (
                  <Event {...props} user={user} showSnackbar={showSnackbar} />
                )}
              />
              <Route path="/players/:id" component={Player} />
              <AdminRoute
                exact
                path="/admin"
                component={Admin}
                {...adminProps}
              />
              <AdminRoute
                path="/admin/:table"
                component={AdminEdit}
                {...adminProps}
              />
              <Route path="/" component={Events} />
            </Switch>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleClose}
              severity={snackbarSeverity}
              variant="filled"
              elevation={6}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
