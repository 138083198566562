import React from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import Image from "./Image";
import MatchDialog from "./MatchDialog";
import getWinner from "./utils/getWinner";
import isAdmin from "./utils/isAdmin";
import { secondsToTime } from "./utils/time";

import styles from "./Bracket.module.scss";

export const BRACKET_INDEX = {
  2: [0],
  4: [0, 1],
  8: [0, 3, 2, 1],
  16: [0, 7, 4, 3, 2, 5, 6, 1],
};

const SEED_NOT_FOUND = 999;

export class Bracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatchDialogOpen: false,
      match: null,
      round: null,
    };
  }

  setMatchDialogOpen(isOpen, match, round) {
    this.setState({
      isMatchDialogOpen: isOpen,
      match,
      round,
    });
  }

  updateBracket() {
    this.props.updateEvent();
  }

  render() {
    const { isMatchDialogOpen, match, round } = this.state;
    const { rounds, seeds, playerMap, title, user, showSnackbar } = this.props;
    const numPlayers = Math.min(seeds.length, 16);
    let numRounds = 0;
    if (rounds) {
      numRounds = rounds.length;
    } else {
      for (let i = 0; i < 4; i++) {
        if (numPlayers > (i === 0 ? 0 : Math.pow(2, i))) {
          numRounds++;
        } else {
          break;
        }
      }
    }
    const maxPlayers = numRounds > 0 ? Math.pow(2, numRounds) : 0;
    const matchups = [];
    if (numRounds > 0) {
      matchups.push({ matchups: [] });
    }
    for (let i = 0; i < numRounds; i++) {
      const numMatchesInRound = Math.pow(2, numRounds - i - 1);
      matchups[i] = {
        matchups: [...new Array(numMatchesInRound)].map((_, index) => ({
          key: i + "-" + index,
          player1: {},
          player2: {},
        })),
      };
    }
    if (rounds) {
      for (let i = 0; i < rounds.length; i++) {
        const round = rounds[i];
        matchups[i].round = round;
        const matches = round.matches;
        for (let j = 0; j < matches.length; j++) {
          const match = matches[j];
          const player1 = {
            playerId: match.player_1_id,
            seed: match.player_1_id
              ? seeds.find((seed) => seed.player_id === match.player_1_id).seed
              : SEED_NOT_FOUND,
          };
          const player2 = {
            playerId: match.player_2_id,
            seed: match.player_2_id
              ? seeds.find((seed) => seed.player_id === match.player_2_id).seed
              : SEED_NOT_FOUND,
          };
          const matchupIndex =
            (match.match_number - 1) %
            Math.round(
              Math.pow(maxPlayers, (rounds.length - i) / rounds.length)
            );
          matchups[i].matchups[matchupIndex].match = match;
          if (player1.playerId || player2.playerId) {
            matchups[i].matchups[matchupIndex].player1 = player1;
            matchups[i].matchups[matchupIndex].player2 = player2;
            matchups[i].matchups[
              matchupIndex
            ].player1.gamesWon = match.games.filter(
              (game) =>
                game.winning_player_number_forced === 1 ||
                game.winning_player_number_forced === 0 && (
                  game.player_2_time_seconds == null ||
                  game.player_2_time_seconds === 0 ||
                  (game.player_1_time_seconds != null &&
                    game.player_1_time_seconds !== 0 &&
                    game.player_1_time_seconds < game.player_2_time_seconds))
            );
            matchups[i].matchups[
              matchupIndex
            ].player2.gamesWon = match.games.filter(
              (game) =>
                game.winning_player_number_forced === 2 ||
                game.winning_player_number_forced === 0 && (
                  game.player_1_time_seconds == null ||
                  game.player_1_time_seconds === 0 ||
                  (game.player_2_time_seconds != null &&
                    game.player_2_time_seconds !== 0 &&
                    game.player_2_time_seconds < game.player_1_time_seconds))
            );
            const winner = getWinner(match, round);
            matchups[i].matchups[matchupIndex].player1.isWinner =
              winner !== null && winner === player1.playerId;
            matchups[i].matchups[matchupIndex].player2.isWinner =
              winner !== null && winner === player2.playerId;
          }
        }
      }
    } else {
      for (let i = 0; i < maxPlayers / 2; i++) {
        const index = BRACKET_INDEX[maxPlayers][i];
        const player2Seed = maxPlayers - i;
        const player2 =
          maxPlayers - i > numPlayers
            ? { seed: player2Seed }
            : {
                playerId: seeds[maxPlayers - i - 1].player_id,
                seed: player2Seed,
              };
        matchups[0].matchups[index] = {
          key: i,
          player1: { playerId: seeds[i].player_id, seed: i + 1 },
          player2,
        };
      }
    }
    const isAdminUser = isAdmin(user);
    let matchNumber = 1;
    for (let i = 0; i < matchups.length; i++) {
      for (let j = 0; j < matchups[i].matchups.length; j++) {
        const { round } = matchups[i];
        const { key, player1, player2, match } = matchups[i].matchups[j];
        let cardContent = (
          <>
            <Typography
              variant="body2"
              className={
                styles.Seed + (player1.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player1.seed === SEED_NOT_FOUND ? "" : player1.seed}
            </Typography>
            <Typography
              variant="body2"
              className={
                styles.GamerTag + (player1.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player1 ? (
                playerMap.has(player1.playerId) ? (
                  <Link
                    to={"/players/" + player1.playerId}
                    className={styles.Link}
                  >
                    {playerMap.get(player1.playerId).gamer_tag}
                  </Link>
                ) : i > 0 ? (
                  <span className={styles.WinnerOf}>
                    Winner of{" "}
                    {getPreviousMatchNumber(numRounds, matchNumber, 1)}
                  </span>
                ) : (
                  <span className={styles.Bye}>-</span>
                )
              ) : (
                ""
              )}
            </Typography>
            <div
              className={
                styles.Wins + (player1.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player1 && player1.gamesWon
                ? player1.gamesWon.map((game, i) => (
                    <Tooltip
                      key={key + "-1-" + i}
                      title={
                        <>
                          <Typography variant="h6">
                            Game {game.game_number}
                          </Typography>
                          <Typography variant="body2">
                            {player1.playerId
                              ? playerMap.get(player1.playerId).gamer_tag
                              : "Unknown"}
                            :{" "}
                            {game.player_1_time_seconds
                              ? secondsToTime(game.player_1_time_seconds)
                              : game.player_1_score_notation}
                          </Typography>
                          <Typography variant="body2">
                            {player2.playerId
                              ? playerMap.get(player2.playerId).gamer_tag
                              : "Unknown"}
                            :{" "}
                            {game.player_2_time_seconds
                              ? secondsToTime(game.player_2_time_seconds)
                              : game.player_2_score_notation}
                          </Typography>
                        </>
                      }
                    >
                      <div className={styles.ImageWrapper}>
                        <Image src="/crown.png" />
                      </div>
                    </Tooltip>
                  ))
                : ""}
            </div>
            <Typography
              variant="body2"
              className={
                styles.Seed + (player2.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player2.seed === SEED_NOT_FOUND ? "" : player2.seed}
            </Typography>
            <Typography
              variant="body2"
              className={
                styles.GamerTag + (player2.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player2 ? (
                playerMap.has(player2.playerId) ? (
                  <Link
                    to={"/players/" + player2.playerId}
                    className={styles.Link}
                  >
                    {playerMap.get(player2.playerId).gamer_tag}
                  </Link>
                ) : i > 0 ? (
                  <span className={styles.WinnerOf}>
                    Winner of{" "}
                    {getPreviousMatchNumber(numRounds, matchNumber, 2)}
                  </span>
                ) : (
                  <span className={styles.Bye}>-</span>
                )
              ) : (
                ""
              )}
            </Typography>
            <div
              className={
                styles.Wins + (player2.isWinner ? ` ${styles.Winner}` : "")
              }
            >
              {player2 && player2.gamesWon
                ? player2.gamesWon.map((game, i) => (
                    <Tooltip
                      key={key + "-1-" + i}
                      title={
                        <>
                          <Typography variant="h6">
                            Game {game.game_number}
                          </Typography>
                          <Typography variant="body2">
                            {playerMap.has(player2.playerId)
                              ? playerMap.get(player2.playerId).gamer_tag
                              : "Unknown"}
                            :{" "}
                            {game.player_2_time_seconds
                              ? secondsToTime(game.player_2_time_seconds)
                              : game.player_2_score_notation}
                          </Typography>
                          <Typography variant="body2">
                            {playerMap.has(player1.playerId)
                              ? playerMap.get(player1.playerId).gamer_tag
                              : "Unknown"}
                            :{" "}
                            {game.player_1_time_seconds
                              ? secondsToTime(game.player_1_time_seconds)
                              : game.player_1_score_notation}
                          </Typography>
                        </>
                      }
                    >
                      <div className={styles.ImageWrapper}>
                        <Image src="/crown.png" />
                      </div>
                    </Tooltip>
                  ))
                : ""}
            </div>
          </>
        );
        const matchNumberEl = (
          <div className={styles.MatchNumber}>{matchNumber}</div>
        );
        const showButton = isAdminUser && rounds && rounds.length > 0;
        if (showButton) {
          cardContent = (
            <>
              {matchNumberEl}
              <CardActionArea
                className={`${styles.Button} ${styles.Grid}`}
                onClick={() =>
                  this.setMatchDialogOpen(true /* isOpen */, match, round)
                }
              >
                {cardContent}
              </CardActionArea>
            </>
          );
        } else {
          cardContent = (
            <>
              {matchNumberEl}
              <div className={styles.Grid}>{cardContent}</div>
            </>
          );
        }
        matchups[i].matchups[j].card = (
          <Card className={styles.Match}>{cardContent}</Card>
        );
        matchNumber++;
      }
    }
    const renderedTitle = title ? (
      <Typography variant="h5">{title}</Typography>
    ) : (
      ""
    );
    const matchDialog = isMatchDialogOpen ? (
      <MatchDialog
        open={isMatchDialogOpen}
        closeDialog={() => this.setMatchDialogOpen(false)}
        seeds={seeds}
        playerMap={playerMap}
        match={match}
        round={round}
        updateBracket={() => this.updateBracket()}
        showSnackbar={showSnackbar}
      />
    ) : (
      ""
    );
    return (
      <div className={styles.BracketContainer}>
        {renderedTitle}
        <div className={styles.Bracket}>
          {matchups.map((round, i) => (
            <div className={styles.RoundContainer} key={i}>
              <div className={styles.RoundTitle}>
                <Typography variant="h6">
                  {round.round ? round.round.name : "Round " + (i + 1)}
                </Typography>
                {round.round && (
                  <div className={styles.RoundSubtitle}>
                    Level {round.round.level_start}-{round.round.level_end},
                    Best of {round.round.num_wins_required * 2 - 1}
                  </div>
                )}
              </div>
              <div className={styles.Round}>
                {round.matchups.map(({ key, card }) => (
                  <React.Fragment key={key}>{card}</React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
        {matchDialog}
      </div>
    );
  }
}

function getPreviousMatchNumber(numRounds, matchNum, playerNum) {
  return 2 * (matchNum - Math.pow(2, numRounds - 1) - 1) + playerNum;
}
